import { dom, library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"

export default class Icons
{
    run()
    {
        library.add(far)
        library.add(fas)
        library.add(fab)
    }

    watch()
    {
        window.addEventListener("DOMContentLoaded", () => {
            dom.i2svg()
            dom.watch()
        })
    }
}
