import { v4 as GenerateUuidV4 } from "uuid"
import View from "./../Application/View.js"

export default class Estimate
{
	addButtons = document.querySelectorAll('[data-s~="Product.add.button"]')
	removeButtons = document.querySelectorAll('[data-s~="Product.remove.button"]')
	priceOnceTexts = document.querySelectorAll('[data-s~="Product.price.once.text"]')
	priceAnnuallyTexts = document.querySelectorAll('[data-s~="Product.price.annually.text"]')
	priceDiscountTexts = document.querySelectorAll('[data-s~="Product.price.discount.text"]')
	priceFirstInvoiceTexts = document.querySelectorAll('[data-s~="Product.price.firstInvoice.text"]')
	priceSecondInvoiceTexts = document.querySelectorAll('[data-s~="Product.price.secondInvoice.text"]')
	forms = document.querySelectorAll('[data-s~="Estimate.form"]')
	onceAreas = document.querySelectorAll('[data-s~="Estimate.once.area"]')
	annuallyAreas = document.querySelectorAll('[data-s~="Estimate.annually.area"]')
	sendButtons = document.querySelectorAll('[data-s~="Estimate.send.button"]')
	mailButtons = document.querySelectorAll('[data-s~="Estimate.mail.button"]')

    months = ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"]

    timeoutController
    timeoutTime = 2000

	constructor(store)
	{
        this.store = store
    }

    async useFetch(url)
    {
        try {
            const response = await fetch(url)

            if (!response.ok) {
                throw new Error(response.status)
            } else {
                return await response.json()
            }
        } catch (error) {
            return error
        }
    }

    registerDetails(order)
    {
        this.store.data.Estimate_1__1.details.date = order.date
        this.store.data.Estimate_1__1.details.domain = order.domain
        this.store.data.Estimate_1__1.details.lastName = order.lastName
        this.store.data.Estimate_1__1.details.mail = order.mail
        this.store.data.Estimate_1__1.details.name = order.name
        this.store.data.Estimate_1__1.details.nip = order.nip
        this.store.data.Estimate_1__1.details.note = order.note
        this.store.data.Estimate_1__1.details.number = order.number
        this.store.data.Estimate_1__1.details.phone = order.phone
        this.store.data.Estimate_1__1.details.type = order.type
    }

    updateDetails()
    {
        this.store.data.Estimate_1__1.details.domain = (document.querySelector('[data-s~="Estimate.domain.input"]').value.length == 0) ? null : document.querySelector('[data-s~="Estimate.domain.input"]').value

        this.store.data.Estimate_1__1.details.name = (document.querySelector('[data-s~="Estimate.name.input"]').value.length == 0) ? null : document.querySelector('[data-s~="Estimate.name.input"]').value

        this.store.data.Estimate_1__1.details.lastName = (document.querySelector('[data-s~="Estimate.lastName.input"]').value.length == 0) ? null : document.querySelector('[data-s~="Estimate.lastName.input"]').value

        this.store.data.Estimate_1__1.details.phone = (document.querySelector('[data-s~="Estimate.phone.input"]').value.length == 0) ? null : document.querySelector('[data-s~="Estimate.phone.input"]').value

        this.store.data.Estimate_1__1.details.mail = (document.querySelector('[data-s~="Estimate.mail.input"]').value.length == 0) ? null : document.querySelector('[data-s~="Estimate.mail.input"]').value

        this.store.data.Estimate_1__1.details.nip = (document.querySelector('[data-s~="Estimate.nip.input"]').value.length == 0) ? null : document.querySelector('[data-s~="Estimate.nip.input"]').value

        // this.store.data.Estimate_1__1.details.note = (document.querySelector('[data-s~="Estimate.note.input"]').value.length == 0) ? null : document.querySelector('[data-s~="Estimate.note.input"]').value

        this.store.data.Estimate_1__1.details.nip = (document.querySelector('[data-s~="Estimate.nip.input"]').value.length == 0) ? null : document.querySelector('[data-s~="Estimate.nip.input"]').value
    }

    updateDetailsByNow()
    {
        let now = new Date()

        let year = String(now.getFullYear())
        let month = String(now.getMonth() + 1).padStart(2, "0")
        let day = String(now.getDate()).padStart(2, "0")
        let hours = String(now.getHours()).padStart(2, "0")
        let minutes = String(now.getMinutes()).padStart(2, "0")
        let seconds = String(now.getSeconds()).padStart(2, "0")

        this.store.data.Estimate_1__1.details.date = year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds
        this.store.data.Estimate_1__1.details.number = "av1x"+(year.slice(-2))+month+day+hours+minutes+seconds

        document.querySelector('[data-s~="Estimate.number.input"]').value = this.store.data.Estimate_1__1.details.number
        document.querySelector('[data-s~="Estimate.number.text"]').textContent = this.store.data.Estimate_1__1.details.number
        document.querySelector('[data-s~="Estimate.date.input"]').value = this.store.data.Estimate_1__1.details.date
        document.querySelector('[data-s~="Estimate.date.text"]').textContent = day+" "+this.months[month-1]+" "+year
    }

    resetCalculations()
    {
        this.store.data.Estimate_1__1.calculations.all = 0
        this.store.data.Estimate_1__1.calculations.discount = 0
        this.store.data.Estimate_1__1.calculations.once = 0
        this.store.data.Estimate_1__1.calculations.annually = 0
        this.store.data.Estimate_1__1.calculations.firstInvoice = 0
        this.store.data.Estimate_1__1.calculations.secondInvoice = 0
    }

    updateCalculations()
    {
        // console.log("%c Calc: ", "background: black; color: #bada55")

        Object.values(this.store.data.Estimate_1__1.products.processed).forEach(product => {
            if (product.status["5"] === true) {
                if (product.type == "once") {
                    parseFloat(this.store.data.Estimate_1__1.calculations.once += product.price["1"].amount)
                } else if (product.type == "annually") {
                    parseFloat(this.store.data.Estimate_1__1.calculations.annually += product.price["1"].amount)
                }

                parseFloat(this.store.data.Estimate_1__1.calculations.all += product.price["1"].amount)

                if (product.status["2"] === true || product.status["6"] === true) {
                    parseFloat(this.store.data.Estimate_1__1.calculations.discount += product.price["2"].discount)
                }
            }
        })

        parseFloat(this.store.data.Estimate_1__1.calculations.firstInvoice += (this.store.data.Estimate_1__1.calculations.once / 2))
        parseFloat(this.store.data.Estimate_1__1.calculations.secondInvoice += ((this.store.data.Estimate_1__1.calculations.once / 2) + this.store.data.Estimate_1__1.calculations.annually))

        // console.log(this.store.data.Estimate_1__1.calculations)
    }

    updateProduct(event)
    {
        let uuid = event.target.getAttribute("data-uuid")
        let customPrice = parseFloat(event.target.textContent)

        if (
            typeof customPrice === "number" &&
            !isNaN(customPrice) &&
            customPrice >= 1 &&
            /^[0-9]+(\.[0-9]+)?$/.test(event.target.textContent)
        ) {
            customPrice = customPrice
        } else {
            customPrice = 1
        }

        if (this.store.data.Estimate_1__1.products.all[uuid]) {
            if (
                this.store.data.Estimate_1__1.products.processed[uuid].status["2"] === true ||
                this.store.data.Estimate_1__1.products.processed[uuid].status["6"] === true
            ) {
                let before = this.store.data.Estimate_1__1.products.processed[uuid].price["2"].before
                let discount = before - customPrice
                let percent = Math.round((discount / before) * 100)

                if (customPrice >= before) {
                    this.store.data.Estimate_1__1.products.processed[uuid].status["6"] = false
                    delete this.store.data.Estimate_1__1.products.processed[uuid].price["2"]

                    if (this.store.data.Estimate_1__1.products.processed[uuid].status["2"] === true) {
                        this.store.data.Estimate_1__1.products.processed[uuid].status["2"] = false
                    }
                } else if (customPrice < before) {
                    this.store.data.Estimate_1__1.products.processed[uuid].status["6"] = true
                    this.store.data.Estimate_1__1.products.processed[uuid].price["2"].discount = discount
                    this.store.data.Estimate_1__1.products.processed[uuid].price["2"].percent = percent
                }
            } else if (
                this.store.data.Estimate_1__1.products.processed[uuid].status["2"] === false &&
                this.store.data.Estimate_1__1.products.processed[uuid].status["6"] === false
            ) {
                let before = this.store.data.Estimate_1__1.products.processed[uuid].price["1"].amount
                let discount = before - customPrice
                let percent = Math.round((discount / before) * 100)

                if (customPrice >= before) {
                    this.store.data.Estimate_1__1.products.processed[uuid].status["6"] = false
                    delete this.store.data.Estimate_1__1.products.processed[uuid].price["2"]
                } else if (customPrice < before) {
                    this.store.data.Estimate_1__1.products.processed[uuid].status["6"] = true
                    this.store.data.Estimate_1__1.products.processed[uuid].price["2"] = {
                        "before": before,
                        "discount": discount,
                        "percent": percent,
                    }
                }
            }
        }

        this.store.data.Estimate_1__1.products.processed[uuid].price["1"].amount = customPrice
    }

    resetAreas()
    {
        this.onceAreas.forEach(area => {
            area.innerHTML = ""
		})
        this.annuallyAreas.forEach(area => {
            area.innerHTML = ""
		})
    }

    renderAreas()
    {
        const view = new View()
        let areas = null
        let once = 0
        let annually = 0

        Object.values(this.store.data.Estimate_1__1.products.processed).forEach(product => {
            if (product.type == "once") {
                areas = this.onceAreas
                once += 1
            }
            if (product.type == "annually") {
                areas = this.annuallyAreas
                annually += 1
            }

            let template = null

            if (this.store.data.Estimate_1__1.config.status.store) {
                if (
                    product.status["4"] ||
                    this.store.data.Estimate_1__1.config.status.modifiable
                ) {
                    template = '[data-s~="Product.template.modifiable"]'
                } else {
                    template = '[data-s~="Product.template.default"]'
                }
            } else {
                if (this.store.data.Estimate_1__1.config.status.modifiable) {
                    template = '[data-s~="Product.template.modifiable"]'
                } else {
                    template = '[data-s~="Product.template.default"]'
                }
            }

            areas.forEach(area => {
                area.innerHTML += view.compile(
                    view.get(template),
                    {
                        product,
                        before: { display: (product.status["2"] === true || product.status["6"] === true) ? "fx" : "ne" },
                        rebate: { display: (product.status["6"] === true) ? "fx" : "ne" },
                        discount: { display: (product.status["2"] === true) ? "fx" : "ne" },
                        percent: { display: (product.status["2"] === true || product.status["6"] === true) ? "fx" : "ne" },
                        new: { display: (product.status["1"] === true) ? "fx" : "ne" },
                        remove: { display: (this.store.data.Estimate_1__1.config.status.store || this.store.data.Estimate_1__1.config.status.modifiable) ? "fx" : "ne" }
                    }
                )
            })
        })

        let modifier = (window.innerWidth > 1000) ? 4 : 2

        if ((modifier - (once % modifier)) < modifier) {
            for (let i = 1; i <= (modifier - (once % modifier)); i++) {
                this.onceAreas.forEach(area => {
                    area.innerHTML += view.compile(view.get('[data-s~="Product.template.empty"]'), {})
                })
            }
        }
        if ((4 - (annually % modifier)) < modifier) {
            for (let i = 1; i <= (modifier - (annually % modifier)); i++) {
                this.annuallyAreas.forEach(area => {
                    area.innerHTML += view.compile(view.get('[data-s~="Product.template.empty"]'), {})
                })
            }
        }
    }

    resetPrices()
    {
        this.priceOnceTexts.forEach(text => {
            text.textContent = ""
		})
        this.priceAnnuallyTexts.forEach(text => {
            text.textContent = ""
		})
        this.priceDiscountTexts.forEach(text => {
            text.textContent = ""
		})
        this.priceFirstInvoiceTexts.forEach(text => {
            text.textContent = ""
		})
        this.priceSecondInvoiceTexts.forEach(text => {
            text.textContent = ""
		})
    }

    renderPrices()
    {
        this.priceOnceTexts.forEach(text => {
            text.textContent = this.store.data.Estimate_1__1.calculations.once
		})
        this.priceAnnuallyTexts.forEach(text => {
            text.textContent = this.store.data.Estimate_1__1.calculations.annually
		})
        this.priceDiscountTexts.forEach(text => {
            text.textContent = this.store.data.Estimate_1__1.calculations.discount
		})
        this.priceFirstInvoiceTexts.forEach(text => {
            text.textContent = this.store.data.Estimate_1__1.calculations.firstInvoice
		})
        this.priceSecondInvoiceTexts.forEach(text => {
            text.textContent = this.store.data.Estimate_1__1.calculations.secondInvoice
		})
    }

    reconfigureStructureEstimate()
    {
        this.sendButtons.forEach(button => {
            button.style.display = "flex"
		})
        this.mailButtons.forEach(button => {
            button.style.display = "none"
		})
    }

    reconfigureStructureAfterAdd(products)
    {
        Object.values(products).forEach(product => {
            if (product.status["3"] === false) {
                document.querySelectorAll('[data-s~="Product.add.button"][data-uuid="'+product.uuid+'"]').forEach(button => {
                    button.style.display = "none"
                })
                document.querySelectorAll('[data-s~="Product.remove.button"][data-uuid="'+product.uuid+'"]').forEach(button => {
                    button.style.display = "flex"
                })
            }
		})
    }

    reconfigureStructureAfterRemove(uuids)
    {
        uuids.forEach(uuid => {
            document.querySelectorAll('[data-s~="Product.add.button"][data-uuid="'+uuid+'"]').forEach(button => {
                button.style.display = "flex"
            })
            document.querySelectorAll('[data-s~="Product.remove.button"][data-uuid="'+uuid+'"]').forEach(button => {
                button.style.display = "none"
            })
		})
    }

    add(originProducts)
    {
        // console.log("%c Add product request ", "background: #bada55; color: black")

        Object.values(originProducts).forEach(originProduct => {
            if (originProduct.prices !== undefined) {
                let range = document.querySelector('[data-s~="Product.price.range"][data-uuid="'+originProduct.uuid+'"]')
                let product = structuredClone(originProduct)

                product.text["1"] += " - " + this.store.data.Estimate_1__1.products.all[originProduct.uuid].prices[range.value].text
                product.price["1"].amount = parseFloat(this.store.data.Estimate_1__1.products.all[originProduct.uuid].prices[range.value].amount)
                this.store.data.Estimate_1__1.products.processed[originProduct.uuid] = product
            } else if (originProduct.status["3"] === true) {
                let uuid = GenerateUuidV4()
                let product = structuredClone(originProduct)

                product.uuid = uuid
                this.store.data.Estimate_1__1.products.processed[uuid] = product
            } else {
                let product = structuredClone(originProduct)

                this.store.data.Estimate_1__1.products.processed[originProduct.uuid] = product
            }

            if (
                this.store.data.Estimate_1__1.products.all[originProduct.uuid] &&
                this.store.data.Estimate_1__1.products.all[originProduct.uuid].related &&
                Array.isArray(this.store.data.Estimate_1__1.products.all[originProduct.uuid].related.add) &&
                this.store.data.Estimate_1__1.products.all[originProduct.uuid].related.add.length > 0
            ) {
                this.store.data.Estimate_1__1.products.all[originProduct.uuid].related.add.forEach(uuid => {
                    if (!this.store.data.Estimate_1__1.products.processed[uuid]) {
				        this.add([this.store.data.Estimate_1__1.products.all[uuid]])
                        this.reconfigureStructureAfterAdd([this.store.data.Estimate_1__1.products.all[uuid]])
                    }
                })
            }
        })

        // console.log("%c Products after add: ", "background: black; color: #bada55")
        // console.log(this.store.data.Estimate_1__1.products.processed)
    }

    remove(uuids)
    {
        // console.log("%c Remove product request: "+uuids, "background: #da5568; color: black")

        uuids.forEach(uuid => {
            delete this.store.data.Estimate_1__1.products.processed[uuid]

            if (
                this.store.data.Estimate_1__1.products.all[uuid] &&
                this.store.data.Estimate_1__1.products.all[uuid].related &&
                Array.isArray(this.store.data.Estimate_1__1.products.all[uuid].related.remove) &&
                this.store.data.Estimate_1__1.products.all[uuid].related.remove.length > 0
            ) {
                this.store.data.Estimate_1__1.products.all[uuid].related.remove.forEach(uuid => {
                    if (this.store.data.Estimate_1__1.products.processed[uuid]) {
				        this.remove([uuid])
                        this.reconfigureStructureAfterRemove([uuid])
                    }
                })
            }
		})

        // console.log("%c Products after remove: ", "background: black; color: #da5568")
        // console.log(this.store.data.Estimate_1__1.products.processed)
    }

    send()
    {
        fetch("/kosztorys/wyslij", {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
                details: this.store.data.Estimate_1__1.details,
                calculations: this.store.data.Estimate_1__1.calculations,
                products: {
                    processed: this.store.data.Estimate_1__1.products.processed,
                }
            })
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data)
            this.store.service.Notification.text = "Kosztorys został wysłany pomyślnie."
            this.store.service.Notification.show()
        })
    }

    async run()
    {
        this.store.data.Estimate_1__1.products.all = await this.useFetch("/products/all")

        if (Object.keys(this.store.data.Estimate_1__1.order).length === 0) {
            this.store.data.Estimate_1__1.products.default = await this.useFetch("/products/default")
        } else {
            this.store.data.Estimate_1__1.products.default = await this.useFetch("/boughts/"+this.store.data.Estimate_1__1.order.uuid)
            this.registerDetails(this.store.data.Estimate_1__1.order)
        }

        // console.log("%c Estimate: ", "background: black; color: #bada55")
        // console.log(this.store.data.Estimate_1__1)

        this.add(this.store.data.Estimate_1__1.products.default)
        this.reconfigureStructureAfterAdd(this.store.data.Estimate_1__1.products.default)
        this.resetCalculations()
        this.updateCalculations()
        this.resetAreas()
        this.renderAreas()
        this.resetPrices()
        this.renderPrices()

        this.store.data.Estimate_1__1.details.number = document.querySelector('[data-s~="Estimate.number.input"]').value
        this.store.data.Estimate_1__1.details.date = document.querySelector('[data-s~="Estimate.date.input"]').value
        this.store.data.Estimate_1__1.details.type = document.querySelector('[data-s~="Estimate.type.input"]').value
    }

    watch()
    {
        this.forms.forEach(form => {
            form.addEventListener("submit", event => {
                event.preventDefault()
                this.send()
            })
        })
        this.addButtons.forEach(button => {
			button.addEventListener("click", () => {
				this.add([this.store.data.Estimate_1__1.products.all[button.getAttribute("data-uuid")]])
                this.reconfigureStructureAfterAdd([this.store.data.Estimate_1__1.products.all[button.getAttribute("data-uuid")]])
                this.reconfigureStructureEstimate()
                this.resetCalculations()
                this.updateCalculations()
                this.updateDetailsByNow()
                this.resetAreas()
                this.renderAreas()
                this.resetPrices()
                this.renderPrices()

                this.store.service.Notification.text = "Produkt został dodany pomyślnie."
                this.store.service.Notification.show()
			})
		})
        this.removeButtons.forEach(button => {
			button.addEventListener("click", () => {
				this.remove([button.getAttribute("data-uuid")])
                this.reconfigureStructureAfterRemove([button.getAttribute("data-uuid")])
                this.reconfigureStructureEstimate()
                this.resetCalculations()
                this.updateCalculations()
                this.updateDetailsByNow()
                this.resetAreas()
                this.renderAreas()
                this.resetPrices()
                this.renderPrices()
			})
		})
        addEventListener("click", (event) => {
            if (event.target.getAttribute("data-s") == "Product.remove.button") {
                this.remove([event.target.getAttribute("data-uuid")])
                this.reconfigureStructureAfterRemove([event.target.getAttribute("data-uuid")])
                this.reconfigureStructureEstimate()
                this.resetCalculations()
                this.updateCalculations()
                this.updateDetailsByNow()
                this.resetAreas()
                this.renderAreas()
                this.resetPrices()
                this.renderPrices()
            }
        })
        addEventListener("load", (event) => {
            this.updateDetails()
        })
        addEventListener("input", (event) => {
            this.updateDetails()

            if (event.target.getAttribute("data-s") == "Product.text.input") {
                this.store.data.Estimate_1__1.products.processed[event.target.getAttribute("data-uuid")].text["1"] = event.target.textContent
            }
            if (event.target.getAttribute("data-s") == "Product.price.input") {
                clearTimeout(this.timeoutController)
                this.timeoutController = setTimeout(() => {
                    this.updateProduct(event)
                    this.reconfigureStructureEstimate()
                    this.resetCalculations()
                    this.updateCalculations()
                    this.updateDetailsByNow()
                    this.resetAreas()
                    this.renderAreas()
                    this.resetPrices()
                    this.renderPrices()

                    this.store.service.Notification.text = "Aktualizacja cen kosztorysu."
                    this.store.service.Notification.show()
                }, this.timeoutTime)
            }
            if (event.target.getAttribute("data-s") == "Product.price.range") {
                clearTimeout(this.timeoutController)
                this.timeoutController = setTimeout(() => {
                    this.add([this.store.data.Estimate_1__1.products.all[event.target.getAttribute("data-uuid")]])
                    this.reconfigureStructureAfterAdd([this.store.data.Estimate_1__1.products.all[event.target.getAttribute("data-uuid")]])
                    this.reconfigureStructureEstimate()
                    this.resetCalculations()
                    this.updateCalculations()
                    this.updateDetailsByNow()
                    this.resetAreas()
                    this.renderAreas()
                    this.resetPrices()
                    this.renderPrices()

                    this.store.service.Notification.text = "Aktualizacja cen kosztorysu."
                    this.store.service.Notification.show()
                }, this.timeoutTime)
            }
        })
    }
}
