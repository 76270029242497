export default class Navigation
{
	items = document.querySelectorAll('[data-s~="NavigationModal.item"]')

    show()
	{
        setTimeout(() => {
            this.items.forEach(item => {
                item.style.transform = "translateX(0)"
            })
        }, 250)
    }

    hide()
	{
        this.items.forEach(item => {
            item.style.transform = "translateX(-100vw)"
        })
    }

	watch()
	{
        addEventListener("modal", (e) => {
            if (
                e.detail.action == "show" &&
                e.detail.target == "navigation"
            ) {
                this.show()
            }
            if (
                e.detail.action == "hide"
            ) {
                this.hide()
            }
        })
	}
}
