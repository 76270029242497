export default class Accordion
{
    is = document.querySelectorAll('[data-s~="Accordion.i"]')

    var1 = false

    show(i)
    {
        let uuid = (i.hasAttribute("data-uuid")) ? i.getAttribute("data-uuid") : ""
        let o = document.querySelector('[data-s~="Accordion.o"][data-uuid~="'+uuid+'"]')

        let icon = i.querySelector('[data-s~="Accordion.icon"]')
        if (icon) {
            icon.style.rotate = "180deg"
        }
        i.setAttribute("data-enable", "true")

        o.style.visibility = "initial"

        let clone = o.cloneNode(true)
        Object.assign(clone.style, {
            height: "auto",
        })
        o.after(clone)
        let height = clone.offsetHeight
        clone.remove()
        o.style.height = height+"px"
        setTimeout(() => {
            o.style.height = "initial"
        }, 500)
    }

    hide(i)
    {
        let uuid = (i.hasAttribute("data-uuid")) ? i.getAttribute("data-uuid") : ""
        let o = document.querySelector('[data-s~="Accordion.o"][data-uuid~="'+uuid+'"]')

        let icon = i.querySelector('[data-s~="Accordion.icon"]')
        if (icon) {
            icon.style.rotate = "0deg"
        }
        i.setAttribute("data-enable", "false")

        o.style.height = o.offsetHeight+"px"

        setTimeout(() => {
            o.style.height = "0"
            o.style.visibility = "hidden"
        }, 100)
    }

    check(i)
    {
        let enable = (i.hasAttribute("data-enable") && i.getAttribute("data-enable") === "true") ? true : false

        if (enable) {
            this.hide(i)
        } else {
            this.show(i)
        }
    }

    watch()
    {
        this.is.forEach(i => {
            let type = (i.hasAttribute("data-type")) ? i.getAttribute("data-type") : "click"

            i.addEventListener(type, () => {
                this.check(i)
            })

            if (type == "own-1") {
                i.addEventListener("mouseenter", () => {
                    this.var1 = true

                    this.show(i)
                })
                i.addEventListener("mouseleave", () => {
                    this.var1 = false

                    setTimeout(() => {
                        if (!this.var1) {
                            this.hide(i)
                        }
                    }, 100)
                })
            }
            if (type == "own-2") {
                i.addEventListener("mouseenter", () => {
                    this.var1 = true
                })
                i.addEventListener("mouseleave", () => {
                    this.var1 = false

                    setTimeout(() => {
                        if (!this.var1) {
                            this.hide(i)
                        }
                    }, 100)
                })
            }
		})
    }
}
