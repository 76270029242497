export default class Curtains
{
	is = document.querySelectorAll('[data-s~="Curtain"]')

	hide = (entries, observer) =>
	{
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				setTimeout(() => {
					entry.target.style.transform = "translateY(100%)"
					setTimeout(() => {
						entry.target.remove()
						observer.unobserve(entry.target)
					}, 500)
				}, Math.random() * (2000 - 1000) + 100)
			}
		})
	}

	observe(is, callback, config)
    {
        const intersectionObserver = new IntersectionObserver(callback, config)

        is.forEach(i => {
            intersectionObserver.observe(i)
		})
    }

	watch()
	{
		this.observe(this.is, this.hide, {
            rootMargin: "0px",
            threshold: 0
        })
	}
}
