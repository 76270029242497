export default class ScrollToContent
{
	buttons = document.querySelectorAll('[data-s~="ScrollToContent"]')
	content = document.querySelector("#tresc")

    scroll()
	{
        this.content.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        })
	}

    watch()
	{
        this.buttons.forEach(e => {
            e.addEventListener("click", () => {
                this.scroll()
            })
        })
	}
}
