export default class Notification
{
	notifications = document.querySelectorAll('[data-s~="Notification"]')
	notificationTexts = document.querySelectorAll('[data-s~="Notification.text"]')

    text = "Operacja przebiegła pomyślnie."
    can = true

    show()
    {
        if (this.can) {
            this.can = false

            this.notificationTexts.forEach(text => {
                text.textContent = this.text
            })
            this.notifications.forEach(notification => {
                notification.style.opacity = "1"
                notification.style.transform = "translateY(0)"
            })
            setTimeout(() => {
                this.notifications.forEach(notification => {
                    notification.style.opacity = "0"
                    notification.style.transform = "translateY(40px)"
                })
                setTimeout(() => {
                    this.can = true
                }, 250)
            }, 3000)
        }
    }
}
