export default class Header
{
	headers = document.querySelectorAll('[data-s~="Header"]')
	splashs = document.querySelectorAll('[data-s~="Header.splash"]')

    onSplash = false
    lastScrollTop = 0

    constructor(store)
    {
        this.store = store
    }

    check()
    {
        const currentScroll = window.scrollY
        const isScrollingDown = currentScroll > this.lastScrollTop
        this.lastScrollTop = currentScroll <= 0 ? 0 : currentScroll

        if (this.store.route.path == "/") {
            if ((window.scrollY >= 500 && this.onSplash) || isScrollingDown) {
                this.hide()
            } else if (window.scrollY < 500 || !isScrollingDown) {
                this.show()
            }

            if (window.scrollY < 500) {
                this.v1()
                this.headers.forEach(header => {
                    header.querySelectorAll('[data-s~="Header.strap"]').forEach(e => {
                        e.style.display = "none"
                    })
                })
            } else if (!this.onSplash) {
                this.v2()
                this.headers.forEach(header => {
                    header.querySelectorAll('[data-s~="Header.strap"]').forEach(e => {
                        e.style.display = "flex"
                    })
                })
            }
        } else {
            if ((window.scrollY >= 500 && window.scrollY < 1000) || isScrollingDown) {
                this.hide()
            } else if (window.scrollY < 500 || window.scrollY >= 1000 || !isScrollingDown) {
                this.show()
            }

            if (window.scrollY < 500) {
                this.v1()
                this.headers.forEach(header => {
                    header.querySelectorAll('[data-s~="Header.strap"]').forEach(e => {
                        e.style.display = "flex"
                    })
                })
            } else if (window.scrollY >= 1000) {
                this.v2()
                this.headers.forEach(header => {
                    header.querySelectorAll('[data-s~="Header.strap"]').forEach(e => {
                        e.style.display = "flex"
                    })
                })
            }
        }
    }

    show()
    {
        this.headers.forEach(header => {
            header.style.transform = "translateY(0)"
        })
    }

    hide()
    {
        this.headers.forEach(header => {
            header.style.transform = "translateY(-100px)"
        })
    }

    v1()
    {
        this.headers.forEach(header => {
            header.querySelectorAll('[data-s~="Header.header"]').forEach(e => {
                let data = e.getAttribute("data-d")
                data = data.replace("bd-cr=bk", (this.store.route.path == "/") ? "bd-cr=tt" : "bd-cr=we")
                e.setAttribute("data-d", data)
            })
            header.querySelectorAll('[data-s~="Header.strap"]').forEach(e => {
                let data = e.getAttribute("data-d")
                data = data.replace("bd-cr=8", "bd-cr=3")
                e.setAttribute("data-d", data)
            })
            header.querySelectorAll('[data-s~="Header.logo"]').forEach(e => {
                let data = e.getAttribute("data-a")
                data = data.replace("fx", (this.store.route.path == "/") ? "ne" : "fx")
                e.setAttribute("data-a", data)
            })
            header.querySelectorAll('[data-s~="Header.text"]').forEach(e => {
                let data = e.getAttribute("data-d")
                data = data.replace("co=we", "co=10")
                data = data.replace("ft-se=14rem", "ft-se=18rem")
                e.setAttribute("data-d", data)
            })
            header.querySelectorAll('[data-s~="Navigation.icon"]').forEach(e => {
                let data = e.getAttribute("data-d")
                data = data.replace("co=we", "co=10")
                data = data.replace("ft-se=14rem", "ft-se=18rem")
                e.setAttribute("data-d", data)
            })
            header.querySelectorAll('[data-s~="Navigation.text"]').forEach(e => {
                let data = e.getAttribute("data-d")
                data = data.replace("co=we", "co=10")
                e.setAttribute("data-d", data)
            })
        })
    }

    v2()
    {
        this.headers.forEach(header => {
            header.querySelectorAll('[data-s~="Header.header"]').forEach(e => {
                let data = e.getAttribute("data-d")
                data = data.replace((this.store.route.path == "/") ? "bd-cr=tt" : "bd-cr=we", "bd-cr=bk")
                e.setAttribute("data-d", data)
            })
            header.querySelectorAll('[data-s~="Header.strap"]').forEach(e => {
                let data = e.getAttribute("data-d")
                data = data.replace("bd-cr=3", "bd-cr=8")
                e.setAttribute("data-d", data)
            })
            header.querySelectorAll('[data-s~="Header.logo"]').forEach(e => {
                let data = e.getAttribute("data-a")
                data = data.replace((this.store.route.path == "/") ? "ne" : "fx", "fx")
                e.setAttribute("data-a", data)
            })
            header.querySelectorAll('[data-s~="Header.text"]').forEach(e => {
                let data = e.getAttribute("data-d")
                data = data.replace("co=10", "co=we")
                e.setAttribute("data-d", data)
            })
            header.querySelectorAll('[data-s~="Navigation.icon"]').forEach(e => {
                let data = e.getAttribute("data-d")
                data = data.replace("co=10", "co=we")
                e.setAttribute("data-d", data)
            })
            header.querySelectorAll('[data-s~="Navigation.text"]').forEach(e => {
                let data = e.getAttribute("data-d")
                data = data.replace("co=10", "co=we")
                e.setAttribute("data-d", data)
            })
        })
    }

    splash = (entries, observer) =>
    {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                this.onSplash = true
            } else {
                this.onSplash = false
            }
        })
    }

    observe(elements, callback, config)
    {
        const intersectionObserver = new IntersectionObserver(callback, config)

        elements.forEach(element => {
            intersectionObserver.observe(element)
		})
    }

    watch()
    {
        this.observe(this.splashs, this.splash, {
            rootMargin: "0px",
            threshold: 0
        })
        addEventListener("DOMContentLoaded", () => {
            this.check()
        })
        addEventListener("load", () => {
            this.check()
        })
        addEventListener("scroll", () => {
            this.check()
        })
        addEventListener("resize", () => {
            this.check()
        })
    }
}
