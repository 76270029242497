export default class Counter
{
	counters = document.querySelectorAll('[data-s~="Counter"]')

    count(e)
    {
        let to = e.getAttribute("data-count-to")

        for (let i = 0; i < to.length; i++) {
            let div = document.createElement("div")

            for (let i = 0; i < 2; i++) {
                for (let i = 0; i <= 9; i++) {
                    let p = document.createElement("p")
                    p.textContent = i
                    div.appendChild(p)
                }
            }

            e.appendChild(div)

            setTimeout(() => {
                div.style.transitionDelay = (Math.floor(Math.random() * (1000 - 0 + 1)) + 0)+"ms"
                div.style.transform = "translateY(-1"+to[i]+"00%)"
            }, 100)
        }
    }

    clear(e)
    {
        e.textContent = ""
    }

    call = (entries, observer) =>
    {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                this.count(entry.target)
            } else {
                this.clear(entry.target)
            }
        })
    }

    observe(elements, callback, config)
    {
        const intersectionObserver = new IntersectionObserver(callback, config)

        elements.forEach(element => {
            intersectionObserver.observe(element)
		})
    }

    watch()
    {
        this.observe(this.counters, this.call, {
            rootMargin: "0px",
            threshold: 0
        })
    }
}
