export default class Tooltip
{
    is = document.querySelectorAll("[title]")

    spaceX = 0
    spaceY = 20
    unit = "px"
    width = 0
    height = 0

    resolution()
    {
        this.width = document.body.clientWidth
        this.height = window.innerHeight
    }

    set(v)
    {
        this.tooltip.style.display = "flex"

        if (v.clientX <= (this.width / 2)) {
            this.tooltip.style.left = v.clientX + this.spaceX + this.unit
        }
        if (v.clientX > (this.width / 2)) {
            this.tooltip.style.right = (this.width - v.clientX) + this.spaceX + this.unit
        }
        if (v.clientY <= (this.height / 2)) {
            this.tooltip.style.top = v.clientY + this.spaceY + this.unit
        }
        if (v.clientY > (this.height / 2)) {
            this.tooltip.style.bottom = (this.height - v.clientY) + this.spaceY + this.unit
        }

        if (v.clientX <= (this.width / 2) && v.clientY <= (this.height / 2)) {
            this.gamma.style.display = "flex"
        }
        if (v.clientX > (this.width / 2) && v.clientY <= (this.height / 2)) {
            this.delta.style.display = "flex"
        }
        if (v.clientX > (this.width / 2) && v.clientY > (this.height / 2)) {
            this.beta.style.display = "flex"
        }
        if (v.clientX <= (this.width / 2) && v.clientY > (this.height / 2)) {
            this.alpha.style.display = "flex"
        }
    }

    reset()
    {
        this.tooltip.style.display = null
        this.tooltip.style.top = null
        this.tooltip.style.right = null
        this.tooltip.style.bottom = null
        this.tooltip.style.left = null

        this.gamma.style.display = null
        this.delta.style.display = null
        this.beta.style.display = null
        this.alpha.style.display = null
    }

    run()
    {
        this.resolution()

        if (this.width > 1000) {
            this.is.forEach(i => {
                i.setAttribute("data-title", i.getAttribute("title"))
            })

            const template = document.querySelector('[data-s~="Tooltip.template.default"]')

            if (template) {
                const content = template.content
                const clone = document.importNode(content, true)
                document.body.appendChild(clone)
                template.remove()

                this.tooltip = document.querySelector('[data-s~="Tooltip"]')
                this.text = document.querySelector('[data-s~="Tooltip.text"]')

                this.gamma = document.querySelector('[data-s~="Tooltip.gamma"]')
                this.delta = document.querySelector('[data-s~="Tooltip.delta"]')
                this.beta = document.querySelector('[data-s~="Tooltip.beta"]')
                this.alpha = document.querySelector('[data-s~="Tooltip.alpha"]')
            }
        }
    }

    watch()
    {
        if (this.width > 1000) {
            this.is.forEach(i => {
                i.addEventListener("mousemove", (event) => {
                    this.reset()
                    this.text.textContent = i.getAttribute("data-title")
                    i.removeAttribute("title")
                    this.set(event)
                })
                i.addEventListener("mouseleave", () => {
                    this.text.textContent = ""
                    i.setAttribute("title", i.getAttribute("data-title"))
                    this.reset()
                })
            })
            addEventListener("load", () => {
                this.resolution()
            })
            addEventListener("scroll", () => {
                this.resolution()
            })
            addEventListener("resize", () => {
                this.resolution()
            })
        }
    }
}
