import { Loader } from "@googlemaps/js-api-loader"
import style from "./../Data/style.js"

export default class Map
{
    is = document.querySelectorAll('[data-s~="Map"]')

    apiKey = "AIzaSyDjh_3MbCNig4B1_jNwIgvM8SLxnePa7mw"
    version = "weekly"

    config = {
        center: {
            lat: 50.493354,
            lng: 19.417982
        },
        zoom: 9,
        styles: style
    }

    async mount(i)
    {
        const loader = new Loader({
            apiKey: this.apiKey,
            version: this.version,
        })

        const { Map } = await loader.importLibrary("maps")
        const { Marker } = await loader.importLibrary("marker")

        const map = new Map(i, this.config)

        new Marker({
            map: map,
            position: this.config.center,
            icon: {
                url: "/resources/Marker_1__1.webp",
            }
        })
    }

    run()
    {
        this.is.forEach(i => {
            this.mount(i)
        })
    }
}
