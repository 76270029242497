export default class Cursor
{
    o = document.querySelector('[data-s~="Cursor"]')
    is = document.querySelectorAll('[data-s~="Cursor.i"]')

    show()
    {
        this.o.style.scale = 1
    }

    hide()
    {
        this.o.style.scale = 0
    }

    move(event)
    {
        this.o.style.left = (event.clientX-40)+"px"
        this.o.style.top = (event.clientY-40)+"px"
    }

    watch()
    {
        if (this.o) {
            this.is.forEach(i => {
                i.addEventListener("mousemove", event => {
                    this.move(event)
                    this.show()
                })
                i.addEventListener("mouseleave", event => {
                    this.hide()
                })
            })
        }
    }
}
