export default class Tab
{
    triggers = document.querySelectorAll('[data-s~="Tab:trigger"]')

    toggle(e)
    {
        let uuid = e.getAttribute("data-uuid")
        let group = e.getAttribute("data-group")
        let target = document.querySelector('[data-s~="Tab:target"][data-uuid~="'+uuid+'"][data-group~="'+group+'"]')
        let targets = document.querySelectorAll('[data-s~="Tab:target"][data-group~="'+group+'"]')
        let triggers = document.querySelectorAll('[data-s~="Tab:trigger"][data-group~="'+group+'"]')

        triggers.forEach(trigger => {
            trigger.style.background = null
            trigger.querySelector('[data-s~="Tab:text"]').style.color = null
		})

        e.style.background = getComputedStyle(document.documentElement).getPropertyValue("--color-gradient-theme-1")
        e.querySelector('[data-s~="Tab:text"]').style.color = getComputedStyle(document.documentElement).getPropertyValue("--color-we")

        targets.forEach(target => {
            target.style.display = "none"
		})

        target.style.display = "flex"
    }

    run()
    {
        this.triggers.forEach(trigger => {
            if ((trigger.getAttribute("data-enable") === "true") ? true : false) {
                this.toggle(trigger)
            }
		})
    }

    watch()
    {
        this.triggers.forEach(trigger => {
            trigger.addEventListener("click", () => {
				this.toggle(trigger)
			})
		})
    }
}
