export default class View
{
    get(name)
    {
        return document.querySelector(name).innerHTML
    }

    compile(html, data)
    {
        return html.replace(/{v(.*?)v}/g, (match) => {
            let what = match.split(/{v|v}/).filter(Boolean)[0].trim()
            const get = (t, p) => p.split(".").reduce((r, k) => r?.[k], t)
            return get(data, what)
        })
    }
}
