export default class Video
{
	is = document.querySelectorAll('[data-s~="Video.switch"]')
	videosMain = document.querySelectorAll('[data-s~="Video.main"]')
	buttonsMute = document.querySelectorAll('[data-s~="Video.mute"]')
	buttonsUnmute = document.querySelectorAll('[data-s~="Video.unmute"]')

	width = 0
	height = 0
	type = "default"

	visible = (entries, observer) =>
	{
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				entry.target.play()
			} else {
				entry.target.pause()
            }
		})
	}

	observe(is, callback, config)
    {
        const intersectionObserver = new IntersectionObserver(callback, config)

        is.forEach(i => {
            intersectionObserver.observe(i)
		})
    }

	resolution()
    {
        this.width = document.body.clientWidth
        this.height = window.innerHeight
    }

	check()
    {
		if (this.width >= 1000) {
			if (this.type == "mobile" || this.type == "default") {
				this.videosMain.forEach(video => {
					this.type = "desktop"
					video.querySelectorAll("source").forEach(source => {
						source.src = "/resources/MainDesktop_1__1.mp4"
					})
					video.load()
					video.play()
				})
			}
		} else if (this.width < 1000) {
			if (this.type == "desktop") {
				this.videosMain.forEach(video => {
					this.type = "mobile"
					video.querySelectorAll("source").forEach(source => {
						source.src = "/resources/MainMobile_1__1.mp4"
					})
					video.load()
					video.play()
				})
			}
		}
    }

	mute()
    {
		this.videosMain.forEach(video => {
            video.muted = true
		})
		this.buttonsMute.forEach(button => {
			button.style.display = "none"
		})
		this.buttonsUnmute.forEach(button => {
			button.style.display = "flex"
		})
    }

	unmute()
    {
		this.videosMain.forEach(video => {
            video.muted = false
		})
		this.buttonsMute.forEach(button => {
			button.style.display = "flex"
		})
		this.buttonsUnmute.forEach(button => {
			button.style.display = "none"
		})
    }

	watch()
	{
		this.observe(this.is, this.visible, {
            rootMargin: "0px",
            threshold: 0.25
        })
		this.observe(this.videosMain, this.visible, {
            rootMargin: "0px",
            threshold: 0
        })
		this.buttonsMute.forEach(button => {
			button.addEventListener("click", () => {
				this.mute()
			})
		})
		this.buttonsUnmute.forEach(button => {
			button.addEventListener("click", () => {
				this.unmute()
			})
		})
		// addEventListener("load", () => {
        //     this.resolution()
        //     this.check()
        // })
        // addEventListener("scroll", () => {
        //     this.resolution()
        //     this.check()
        // })
        // addEventListener("resize", () => {
        //     this.resolution()
        //     this.check()
        // })
	}
}
