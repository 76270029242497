import Lenis from "lenis"

export default class SmoothScroll
{
    run()
    {
        if (window.innerWidth > 1000) {
            const lenis = new Lenis()

            function raf(time) {
                lenis.raf(time)
                requestAnimationFrame(raf)
            }

            requestAnimationFrame(raf)
        }
    }
}
