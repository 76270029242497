import Navigo from "navigo"

export default class Router
{
    path = "/"

    constructor(store)
    {
        this.store = store
        this.router = new Navigo(this.path)
        this.store.route.path = "/"+this.getCurrentLocation().url
    }

    on(path, callback)
    {
        this.router.on(path, callback)
    }

    notFound(callback)
    {
        this.router.notFound(callback)
    }

    resolve()
    {
        this.router.resolve()
    }

    getCurrentLocation()
    {
        return this.router.getCurrentLocation()
    }
}
