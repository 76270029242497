export default class ScrollText
{
    scrollTexts = document.querySelectorAll('[data-s~="ScrollText"]')

    watch()
    {
        this.scrollTexts.forEach(scrollText => {
            const element = document.querySelector('[data-s~="ScrollText.scale"]')

            window.addEventListener("scroll", () => {
                let rect = scrollText.getBoundingClientRect()
                let top = rect.top
                let moduleHeight = scrollText.offsetHeight
                let windowHeight = window.innerHeight

                let percentage = (top / parseFloat(moduleHeight + windowHeight)) * -100

                let scale = percentage * 1

                if (percentage <= 1) {
                    scale = 1
                }
                if (percentage >= 100) {
                    scale = 100
                }

                element.style.transform = "scale("+scale+")"
            })
		})
    }
}
