export default class Writer
{
    writers = document.querySelectorAll('[data-s~="Writer"]')

    config = {
        rootMargin: "0px",
        threshold: 1
    }

    constructor()
    {
        this.observer = new IntersectionObserver((writers, observer) => {
            this.run(writers, observer)
        }, this.config)
    }

    run(writers, observer)
    {
        writers.forEach(writer => {
			if (writer.isIntersecting) {
                this.write(writer.target)
                observer.unobserve(writer.target)
			}
		})
    }

    write(writer)
    {
        let bubble = writer.querySelector('[data-s~="Writer.bubble"]')
        let container = writer.querySelector('[data-s~="Writer.container"]')
        let text = writer.querySelector('[data-s~="Writer.text"]')
        let bounce = writer.querySelector('[data-s~="Writer.bounce"]')
        let bubbleDelay = bubble.getAttribute("data-delay")
        let textDelay = text.getAttribute("data-delay")
        let bounceDelay = bounce.getAttribute("data-delay")

        let textToWrite = text.getAttribute("data-text")
        let textSplited = textToWrite.split(" ")

        setTimeout(() => {
            bubble.style.display = "flex"

            setTimeout(() => {
                    bounce.style.display = "none"
                    container.style.display = "flex"

                textSplited.forEach((textFragment, index) => {
                    setTimeout(() => {
                        text.textContent += textFragment + " "
                    }, index * textDelay)
                })
            }, bounceDelay)
        }, bubbleDelay)
    }

    watch()
    {
        this.writers.forEach(writer => {
            this.observer.observe(writer)
		})
    }
}
