export default class Print
{
	is = document.querySelectorAll('[data-s~="Print"]')

    print()
    {
        window.print()
    }

    watch()
    {
        this.is.forEach(i => {
			i.addEventListener("click", () => {
                this.print()
			})
		})
    }
}
