export default class Splash
{
    splashs = document.querySelectorAll('[data-s~="Splash"]')

    width = 0
	height = 0

    resolution()
    {
        this.width = document.body.clientWidth
        this.height = window.innerHeight
    }

    check()
    {
        if (this.width >= 1000) {
            let modifier = (this.width > 1000) ? 35 : 30

            this.splashs.forEach(splash => {
                const container = splash.querySelector('[data-s~="Splash.container"]')
                const screen = splash.querySelector('[data-s~="Splash.screen"]')

                const rectA = splash.getBoundingClientRect()
                const rectB = container.getBoundingClientRect()

                const totalScrollHeight = rectA.bottom - rectA.top - window.innerHeight
                const remainingSpace = rectA.bottom - rectB.bottom
                const scrollProgress = (remainingSpace / totalScrollHeight) * 100
                let progressPercentage = 100 - Math.min(Math.max(scrollProgress, 0), 100)
                const size = modifier + (0 - modifier) * (progressPercentage / 100)

                screen.style.clipPath = "inset("+size+"% round 30px)"
            })
        }
    }

    watch()
    {
        addEventListener("load", () => {
            this.resolution()
            this.check()
        })
        addEventListener("scroll", () => {
            this.resolution()
            this.check()
        })
        addEventListener("resize", () => {
            this.resolution()
            this.check()
        })
    }
}
