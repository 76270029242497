export default class Chat
{
	chats = document.querySelectorAll('[data-s~="Chat"]')
	areas = document.querySelectorAll('[data-s~="Chat.area"]')
	containers = document.querySelectorAll('[data-s~="Chat.container"]')
	buttonsShow = document.querySelectorAll('[data-s~="Chat.button.show"]')
	buttonsHide = document.querySelectorAll('[data-s~="Chat.button.hide"]')
	buttonsWritterHide = document.querySelectorAll('[data-s~="Chat.writter.button.hide"]')
	writters = document.querySelectorAll('[data-s~="Chat.writter"]')

	process = false

	create()
	{
		this.chats.forEach(chat => {
			const iframe = document.createElement("iframe")
			iframe.setAttribute("src", chat.getAttribute("data-src"))

			iframe.style.border = "none"
			iframe.style.outline = "none"
			iframe.style.width = "100%"
			iframe.style.height = "100%"

			this.areas.forEach(area => {
				area.appendChild(iframe)
			})
		})
		this.writters.forEach(writter => {
			writter.style.display = "none"
		})
	}

	show()
	{
		this.containers.forEach(container => {
			container.style.display = "flex"
		})
		this.buttonsHide.forEach(button => {
			button.style.display = "flex"
		})
	}

	hide()
	{
		this.containers.forEach(container => {
			container.style.display = "none"
		})
		this.buttonsHide.forEach(button => {
			button.style.display = "none"
		})
	}

	hideWritters()
	{
		this.writters.forEach(writter => {
			writter.style.display = "none"
		})
	}

    check()
    {
		if (!this.process) {
			this.process = true
			this.create()
		}
    }

	watch()
	{
        this.buttonsShow.forEach(button => {
			button.addEventListener("click", () => {
				this.show()
				this.check()
			})
		})
		this.buttonsHide.forEach(button => {
			button.addEventListener("click", () => {
				this.hide()
			})
		})
		this.buttonsWritterHide.forEach(button => {
			button.addEventListener("click", () => {
				this.hideWritters()
			})
		})
	}
}
