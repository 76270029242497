import Router from "./Router.js"

import Estimate from "../Service/Estimate.js"
import Carousel from "./../Service/Carousel.js"
import Preloader from "./../Service/Preloader.js"
import Curtains from "./../Service/Curtains.js"
import Accordion from "../Service/Accordion.js"
import Tab from "../Service/Tab.js"
import Icons from "../Service/Icons.js"
import Print from "./../Service/Print.js"
import Header from "../Service/Header.js"
import Notification from "../Service/Notification.js"
import Counter from "./../Service/Counter.js"
import ScrollText from "./../Service/ScrollText.js"
import Modal from "./../Service/Modal.js"
import Cookies from "./../Service/Cookies.js"
import Chat from "./../Service/Chat.js"
import Writer from "./../Service/Writer.js"
import Splash from "./../Service/Splash.js"
import ScrollToContent from "./../Service/ScrollToContent.js"
import Video from "./../Service/Video.js"
import SmoothScroll from "./../Service/SmoothScroll.js"
import Navigation from "./../Service/Navigation.js"
import Cursor from "./../Service/Cursor.js"
import Tooltip from "./../Service/Tooltip.js"
import Map from "./../Service/Map.js"
import Animate from "./../Service/Animate.js"

export default class Application
{
    constructor(store)
    {
        this.store = store
    }

    run()
    {
        const router = new Router(this.store)

        router.notFound(() => {})

        router.on("/kosztorys/?", () => {
            this.store.service.Estimate = new Estimate(store)
            this.store.service.Estimate.run()
            this.store.service.Estimate.watch()
        })

        router.resolve()



        this.store.service.Carousel = new Carousel()
        this.store.service.Carousel.run()
        this.store.service.Carousel.watch()

        this.store.service.Preloader = new Preloader()
        this.store.service.Preloader.watch()

        this.store.service.Curtains = new Curtains()
        this.store.service.Curtains.watch()

        this.store.service.Accordion = new Accordion()
        this.store.service.Accordion.watch()

        this.store.service.Tab = new Tab()
        this.store.service.Tab.run()
        this.store.service.Tab.watch()

        this.store.service.Icons = new Icons()
        this.store.service.Icons.run()
        this.store.service.Icons.watch()

        this.store.service.Print = new Print()
        this.store.service.Print.watch()

        this.store.service.Header = new Header(this.store)
        this.store.service.Header.watch()

        this.store.service.Notification = new Notification()
        this.store.service.Counter = new Counter()
        this.store.service.Counter.watch()

        this.store.service.ScrollText = new ScrollText()
        this.store.service.ScrollText.watch()

        this.store.service.Modal = new Modal(this.store)
        this.store.service.Modal.watch()

        this.store.service.Cookies = new Cookies()
        this.store.service.Cookies.run()
        this.store.service.Cookies.watch()

        this.store.service.Chat = new Chat()
        this.store.service.Chat.watch()

        this.store.service.Writer = new Writer()
        this.store.service.Writer.watch()

        this.store.service.Splash = new Splash()
        this.store.service.Splash.watch()

        this.store.service.ScrollToContent = new ScrollToContent()
        this.store.service.ScrollToContent.watch()

        this.store.service.Video = new Video()
        this.store.service.Video.watch()

        this.store.service.SmoothScroll = new SmoothScroll()
        this.store.service.SmoothScroll.run()

        this.store.service.Navigation = new Navigation()
        this.store.service.Navigation.watch()

        this.store.service.Cursor = new Cursor()
        this.store.service.Cursor.watch()

        this.store.service.Tooltip = new Tooltip()
        this.store.service.Tooltip.run()
        this.store.service.Tooltip.watch()

        this.store.service.Map = new Map()
        this.store.service.Map.run()

        this.store.service.Animate = new Animate()
        this.store.service.Animate.run()
    }
}
